// Form styles
form.default, 
.category_select {
	width: 100%;
	font-size: rem($base-font-size);
	padding-top: .8em;

	&.disabled {
		input, textarea, select, button, label {
			pointer-events: none;
			opacity:0.5;

			a
			{
				pointer-events: all;
			}
		}
	}

	::-webkit-input-placeholder {
		/* Chrome/Opera/Safari */
		color: $medium;
	}

	::-moz-placeholder {
		/* Firefox 19+ */
		color: $medium;
	}

	:-ms-input-placeholder {
		/* IE 10+ */
		color: $medium !important;
	}

	:-moz-placeholder {
		/* Firefox 18- */
		color: $medium;
	}

	fieldset {
		border: none;
		
		&:not(:last-of-type) {
			@extend .margin-bottom;
		}
	}

	// label,
	// input,
	// textarea,
	// select,
	// button,
	// [type="submit"] {
		// min-height: 3.5rem;
	// }

	legend,
	.legend {
		@extend .h5;
		@extend .margin-bottom;
		font-weight: 900;
		color: $primary;
	}

	label,
	.label {
		display: block;
		position: relative;
		
		&:not(.sublabel):not([for="career-select"]) {
			margin-bottom: -2.3em;
			padding-left: 1.34em;
			z-index: 1;
			cursor: text;
			color: $primary;
			font-size: 0.75em;
			line-height: 1.2em;
			font-weight: 900;
			
			&:not(:first-of-type) {
				margin-top: 3em;
			}
		}

		small {
			color: $medium;
			background: $light;
			padding: 0.4em 0.6em;
			margin-left: 0.4em;
			font-size: 0.8em;
			border-radius: 5px;
			display: inline-block;
			line-height: 0.5rem;
		}
	}

	.input-box,
	.category-list,
	[type="text"],
	[type="tel"],
	[type="email"],
	[type="file"],
	input,
	textarea,
	select {
		@extend %animatedTransform;
		appearance: none;
		background: $blush;
		border: $base-border;
		border-color: rgba($primary, 0);
		border-radius: $base-border-radius;
		color: $dark;
		display: block;
		font-family: $main-font;
		font-size: inherit;
		line-height: 1.3em;
		margin: 0 0 rem($base-line-height) 0;
		padding: 0.7em 1em;
		padding-top: 2em;
		position: relative;
		resize: none;
		width: 100%;

		&:focus {
			border-color: rgba($primary, 1);
			outline: none;
		}

	}
	[readonly],
	.readonly {
		color: $dark;
		cursor: default;
		pointer-events: none;

		@include input-placeholder {
			color: $dark;
			opacity: 1;
		}
	}

	textarea {
		min-height: 3.5rem;

		$inputgroup-count: 3;

		&[name="Nachricht"] {
			min-height: 3.5rem * $inputgroup-count - 4rem;
		}
	}

	.select-wrapper,
	.category-list  {
		&.icon {
			position: relative;
			
			&:before {
				color: $primary;
				position: absolute;
				right: 1em;	
				bottom: 1em;
				z-index: 2;
				pointer-events: none;
			}
		}
	}
	.select-wrapper {
		padding-top: 0.75em;
	}
	.sublabel {
		display: flex;
		width: 100%;
		align-items: center;

		&.checkbox,
		&.radio {
			position: relative;
			padding-left: 2.3em;
			
			.checkbox-toggle-box {
				display: block;
				position: absolute;
				left: 0;
				top: .3em;
				background: $medium;
				width: 1.6em;
				height: 1em;
				border-radius: 2em;
				padding: .2em;
				cursor: pointer;
				transition: .3s ease-in-out;
				
				.checkbox-toggle {
					display: block;
					width: .6em;
					height: .6em;
					background: $light;
					border-radius: 50%;
					z-index: 1;
					border: none;
					transition: .3s ease-in-out;
				}
			}
			input {
				&[type="checkbox"],
				&[type="radio"] {
					position: absolute;
					top: 0;
					left: 0;
					opacity: 0;

					&:checked {						
						+ .checkbox-toggle-box {
							background: $success;
							
							.checkbox-toggle {
								transform: translate(100%, 0);
							}
						}
					}
				}
			}
			span {
				display: block;
			}
		}
	}
	
	[type="checkbox"] {
		display: inline-block;
		margin: 0 0.5em 0 0;
		padding: 0;
		width: rem(15px);
		height: rem(15px);
		flex-shrink: 0;

		body.iexplore & {
			border: none;

			// &:focus {
			// 	background: none;
			// }
		}
	}

	[type="radio"] {
		margin: 0 rem(15px) 0 0;
        width: rem(15px);
		min-height: auto;
		flex-shrink: 0;
	}

	button,
	[type="submit"] {
		appearance: none;
    }

    div {
        &.checkbox, &.radio {
            display: flex;
            align-items: center;

            &:not(:last-child) {
                @extend .margin-bottom;
            }
            
            label {
                padding-top:0;
                padding-bottom:0;

                &:not(:last-of-type) {
                    margin-right: 2rem;
                }
            }
        }
    }

}
.form-message {
	background: $blush;
	border: $base-border;
	border-color: rgba($primary, 0);
	border-radius: $base-border-radius;
	color: $dark;
	display: block;
	font-family: $main-font;
	line-height: 1.3em;
	margin: 0 0 rem($base-line-height) 0;
	padding: 0.7em 1em;
	position: relative;
	resize: none;
	width: 100%;

	span {
		display: block;
		font-size: .8em;
	}

	.headline {
		font-size: rem(26px);
		font-weight: 200;
		margin-bottom: .5rem;
	}

	&.error {
		background: rgba($alert, .15);
		color: darken($alert, 20%);
	}

	&.success {
		background: rgba($success, .15);
		color: darken($success, 20%);
	}
}
form.default {
	button,
	[type="submit"] {
		@extend .btn;
    }
}

/*=VALIDATION */

.specialfield {
	display: none !important;
	visibility: hidden !important;
}

#newsletter-info {
	background: $alert;
	color: $light;
	font-weight: bold;
	margin-bottom: $base-line-height;
	padding: $base-gap;

	*:last-of-type {
		margin-bottom: 0;
	}
}

img[name="vimg"] + input[name="imgverify"] {
	float: right;
}

//captcha color tweaks
:root {
	--captcha-color-icon-bg-hover: #{$primary};
}

//ticket form only
#ticket-form {
	.hide {
		display: none;
	}

	button {
		display: inline-flex;
		align-items: center;
		gap:10px;
	}
}
[data-form-message] {
	&:not(.show) {
		display: none;
	}
}