// GLOBAL SETTINGS
//////////////////////////////////////////////

$desktop-hover: false; // Set Hover to reach Submenus for Desktop (true, false)

// NAVIGATION
//////////////////////////////////////////////

$nav-bg: $light; // Navigation Background
$nav-color: $primary; // Navigation Link Color

@mixin nav-button {
	// Default Link Styles
	padding: 2em;
	text-decoration: none;
	color: $nav-color;
	line-height: 1.2em;
	font-size: rem(20px);
	font-weight: 700;
	text-align: center;
	justify-content: center !important;
	
	@include breakpoint($break) {
		font-size: clamp(rem(12px), 1vw, rem($base-font-size));
	}
}
%button-hover {
	// Link Hover
	background: $blush;
	color: lighten($primary, 15%);
}
%button-active {
	// Link Active
	background: darken($nav-bg, 5%);

	@include breakpoint($break) {
		color: $secondary;
	}

	
}
%prev-icon {
	// Icon to previus Submenu
	font-family: $icon-font;
	content: map-get($icon-map, arrow-left);
	
	@include breakpoint($break) {
		transform: rotate(90deg);
	}
}
%next-icon {
	// Icon to next Submenu
	font-family: $icon-font;
	content: map-get($icon-map, arrow-right);

	@include breakpoint($break) {
		content: map-get($icon-map, chevron-down);
	}
}
$close-icon: map-get($icon-map, times); // Icon to close Submenu

// Quickbar
//////////////////////////////////////////////

$bar-height: 80px; // height (in px)
$bar-pos: bottom; // position (top, bottom)
$bar-hide: false; // if bar hides on scroll (true, false)

$bar-bg: $primary; // background-color
$bar-color: $light; // children colors – texts and burgerbutton

//////////////////////////////////////////////
// NAVIGATION FUNCTIONALITY
//////////////////////////////////////////////

@import "../modules/ruffnav";

// Navigation Styles
////
@include page-navi {
	background: rgba($light, 0.8);
	backdrop-filter: blur(13px);
	z-index: 1005;
	width: 100%;

	nav {
		@include ul {
			&.navi {
				li {
					&:not(:last-child) {
						border-bottom: $base-border;
					}
					
					@include nav-buttons {
						@include nav-button;

						&:before {
							@extend .icon;
							font-size: 1.4em;
							vertical-align: -0.175em;
							margin-right: 0.3em;
						}
					}
					&.menu-item-12756 {
						@include breakpoint(between, max) {
							display: none;
						}
						
						> * {
							@extend .icon-home;
						}
					}
					&.menu-item-12760 {
						> * {
							@extend .icon-scale;
						}
					}
					&.menu-item-12759 {
						> * {
							@extend .icon-gear;
						}
					}
					&.menu-item-12776 {
						> * {
							@extend .icon-security;
						}
					}
					&.menu-item-12801 {
						> * {
							@extend .icon-add-user;

							&[data-article-count]:after {
								content: attr(data-article-count);
								display: inline-block;
								background: image-url('layout/hexagon.svg') no-repeat center / contain;
								color: $light;
								font-weight: 700;
								font-size: .65em;
								width: 2.4em;
								height: 2.4em;
								line-height: 2.45em;
								text-align: center;
								vertical-align: 1.5em;
								margin-top: -2.4em;
							}
						}
					}
					&.menu-item-12757 {
						> * {
							@extend .icon-bubble;
						}
					}
					&.menu-item-12800 {
						> * {
							@extend .icon-question;
						}
					}
					@include has-sub {
						@include sub {
							background: $nav-bg;

							&:before {
								content: none;
							}
						}
					}
					@include nav-back {
					}

					body.single-post &.menu-item-12757 {
						> a, > span {
							@extend %button-active;
						}
					}
				}
			}
		}
	}
}

// Navigation Desktop Styles
////
@include breakpoint($break) {
	@include desktop-version-functionality;

	#navigation {
		transform: none !important;	
		z-index: 1000;	
		
		body.scrolled & {
			box-shadow: $box-shadow-soft;
		}

		nav {
			max-width: rem($row-max-width);
			margin: 0 auto;

			ul {
				padding-bottom: 0;
				
				&.navi {
					justify-content: flex-start;
					padding-left: clamp(rem(180px), 1vw, rem(200px));
					height: rem(97px);
					
					@include breakpoint(big) {
						padding-left: 0;
						justify-content: center;
					}
					
					> li {
						border: none !important;

						> a, &.menu-item-has-children > span {
							background: none !important;
							padding: 1em clamp(.8em, 1vw, 2em);
							height: 100%;
							display: flex;
							align-items: center;
						}

						body.single-post &#menu-item-12757, 
						body.single-karriere &.menu-item-object-karriere, 
						&.current-menu-parent,
						&.current-menu-item {
							position: relative;

							&:after {
								content: '';
								position: absolute;
								height: 1px;
								border-radius: 0 0 1rem 1rem;
								left: .5em;
								right: .5em;
								top: 0;
								background: $shine;
								
								@include breakpoint(giant) {
									left: 1.2em;
									right: 1.2em;
								}
							}

							> a, > span {
								color: $secondary;
							}
						}
					}
					li {
						&.menu-item-has-children {
							.sub-menu {
								left: 50%;
								transform: translateX(-50%);

								a {	
									font-size: rem(14px);
									padding: 0.7em rem($base-gap);
								}
							}
						}
						&.nav-back {
							padding: 0.3rem;
							font-weight: 400;
							margin: 0.5rem;
							border-radius: 0.3rem;
							background: $primary;
							box-shadow: $box-shadow-glow;
							color: $light;
							transition: 0.6s ease-in-out;
							
							&:hover {
								background: lighten($primary, 15%);
								color: $light;
							}
							&:active {
								background: lighten($primary, 20%);
								color: $light;
							}
							&:focus {
								background: lighten($primary, 25%);
								color: $light;
							}

							&:before {
								font-size: 0.8em;
								width: auto;
								margin: 0;
								vertical-align: unset;
							}
						}
					}
				}
			}
		}
	}
}

// Quickbar Styles
////

#quickbar {
	transition: .3s ease-in-out;
	color: $bar-color;
	z-index: 1003;
	font-size: clamp(rem(14px), 7vw, rem($base-font-size));

	@include breakpoint($break) {
		justify-content: flex-end;
		height: rem(97px);
		bottom: auto;
		top: 0;
		visibility: hidden;
		padding: 0 rem($base-gap);
		font-size: clamp(rem(13px), .6vw, rem($base-font-size));
	}

	.btn,
	label[for="search-toggled"] {
		font-size: inherit;
		display: inline-block;
		cursor: pointer;
		transition: .3s ease-in-out;
		padding: .6em .7em;

		@include breakpoint(huge) {
			padding: .6em 1.2em;
		}

		@include breakpoint($break, max) {
			background: none;
			box-shadow: none;
			color: $light;
			
			@include breakpoint(tiny) {
				color: $primary;
			}
			@include breakpoint(medium) {
				background: $blush;
			}
	
			&:hover {
				color: lighten($primary, 40%);
			}
			&:focus {
				color: lighten($primary, 50%);
			}
		}

		span {
			@include breakpoint(medium, max) {
				display: none;
			}
			@include breakpoint($break) {
				@include breakpoint(giant, max) {
					display: none;
				}
			}
		}
	}
	i {
		line-height: 1;
	}
	.icon {
		&:before {
			font-size: 1.4em;
		}
	}
	.btn.icon {
		display: flex;
		align-items: center;
		gap: 0.5em;

		&:before {
			order: 1;
		}
	}

	> div {
		font-size: inherit;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: space-around;
		gap: 1em;
		background: $bar-bg;
		
		@include breakpoint($break, max) {
			padding: 0 2.24em;
			gap: .7em;
			
			@include breakpoint(tiny) {
				background: linear-gradient(to top, $light, rgba($light, 0.8));
				backdrop-filter: blur(13px);
			}
		}
		@include breakpoint(tiny) {
			justify-content: flex-end;
		}

		> * {
			@include breakpoint($break) {
				visibility: visible;
			}

			body.home &.home,
			body.page-id-155 &.contact {
				.btn {
					@include breakpoint($break, max) {
						color: lighten($primary, 40%);
						position: relative;
		
						&:after {
							content: '';
							position: absolute;
							bottom: 0;
							height: 2px;
							background: $tertiary;
							border-radius: 1em;
							left: 40%;
							right: 40%;
							
						}
						&.icon:after {
							@include breakpoint(medium) {
								left: 0;
								right: 0;
							}
						}
					}
				}
			}
			&.home,
			&.table-of-contents {
				@include breakpoint($break) {
					display: none;
				}

				.btn {
					background: none;
				}
			}
			&.support {
				@include breakpoint($break) {
					order: 1;
				}
				@include breakpoint(giant) {
					a:before {
						display: inline-flex;
						align-items: center;
						min-height: 25px;
					}
				}
				@include breakpoint(huge, max) {
					a span {
						display: none;
					}
				}
			}
			&.contact {
				@include breakpoint($break) {
					order: 2;
				}
			}
			&.table-of-contents {
				i {
					transform: rotate(90deg);
					display: inline-block;
				}
			}
			&.support label,
			&.table-of-contents label {
				@include breakpoint($break, max) {
					&:before {
						content: '';
						position: fixed;
						inset: -110vh -110vw;
						background: $flat;
						opacity: 0;
						visibility: hidden;
						transition: .3s ease-in-out;
					}
				}
			}
			&.search {
				@include breakpoint($break, max) {
					display: none;
				}

				label[for="search-toggled"] {
					color: $primary;
					padding: 1em;
					display: flex;
					width: 100%;
					align-items: center;

					&:after {
						content: '';
						position: fixed;
						inset: rem(97px) 0 0;
						z-index: -1;
						background: $flat;
						backdrop-filter: blur(13px);
						opacity: 0;
						visibility: hidden;
						transition: .5s ease-in-out;
					}
				}
			}
			&.menu {
				padding-left: .6em;

				@include breakpoint($break) {
					display: none;
				}
				
				label[for="navi-toggled"] {
					@extend %animatedTransform;
					box-sizing: content-box;

					@include breakpoint(tiny) {
						span:before,
						span:after {
							background: $primary;
						}
					}
				}
			}
		}
	}
}

#search-toggled {
	display: none;

	~ * #search-form {
		overflow: visible;
		position: fixed;
		bottom: rem($bar-height);
		left: 0;
		z-index: -1;
		transition: .5s ease-in-out;
		font-size: inherit;
		
		@include breakpoint(tiny) {
			position: absolute;
			left: 0.35em;
			bottom: 0.35em;
			z-index: 1;
			width: 35vw;
		}
		@include breakpoint($break) {
			width: clamp(16.5em, 30vw, 30em);
			opacity: 0;
			visibility: hidden;
			right: 50%;
			transform: translate(50%, -100%);
			left: auto;
			bottom: auto;
			top: 8rem;
			box-shadow: $box-shadow-soft;
		}

		body.scrolled.scrollingDown & {
			@include breakpoint(tiny, max) {
				transform: translate(0, 150%);
			}
		}

		input {
			margin: 0;
			min-height: 0;
			padding-right: 4em !important;

			@include breakpoint(tiny, max) {
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 0;
			}
		}
		button {
			@extend %animatedTransform;
			position: absolute;
			top: .8em;
			right: .8em;
			color: $primary;
			cursor: pointer;
			background: none;
			width: 2.6em;
			height: 2.6em;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0;
			text-align: center;
			font-size: 1em;
			z-index: 1;
		}
		&:hover,
		&:focus,
		&:active {
			max-width: 100%;

			label, input {
				opacity: 1;
			}
			button {
				background: lighten($primary, 15%);
				color: $light;
			}
		}
	}

	&:checked {
		~ * #search-form {
			@include breakpoint($break) {
				opacity: 1;
				visibility: visible;
				transform: translate(50%, 0);
			}
		}
		~ * label[for="search-toggled"] {
			color: lighten($primary, 40%);

			&:after {
				opacity: 1 !important;
				visibility: visible !important;
			}
		}
	}
}

#support-toggled {
	display: none;

	~ #support {
		position: fixed;
		bottom: rem($base-gap);
		right: rem($base-gap);
		z-index: 1005;
		width: min-content;
		background: $flat;
		backdrop-filter: blur(13px);
		border-radius: $base-border-radius;
		box-shadow: $box-shadow-soft;
		transform: translate(0, calc(100% - (2em - #{rem($base-gap)})));
		transition: .3s ease-in-out;

		@include breakpoint($break, max) {
			transform: translate(0, 150%);
		}

		label[for="support-toggled"] {
			cursor: pointer;

			i {
				display: block;
				background: $primary;
				clip-path: $hexagon;
				color: $light;
				transition: .3s ease-in-out;
				font-size: rem(26px);
				width: 2.2em;
				height: 2.2em;
				line-height: 2.2em;
				text-align: center;
				margin: -2.3em 0 .4em auto;
			}
			&:after {
				content: '';
				position: absolute;
				inset: .6em 35% auto;
				height: 2px;
				background: rgba($dark, .2);
				border-radius: 1em;
				transition: .3s ease-in-out;
			}

			&:hover {
				i,
				&:after {
					background: lighten($primary, 15%);
				}
			}
			&:active {
				i,
				&:after {
					background: lighten($primary, 20%);
				}
			}
			&:focus {
				i,
				&:after {
					background: lighten($primary, 25%);
				}
			}
		}

		a.icon:before {
			font-weight: 700;
			margin-right: .3em;
			font-size: 1.2em;
		}
	}
	&:checked {
		~ #support {
			transform: translate(0, 0);
		}
		~ #quickbar>div>*.support label:before {
			opacity: 1;
			visibility: visible;
		}
	}
}

#table-of-contents-toggled {
	display: none;

	~ #table-of-contents {
		position: fixed;
		top: 20vh;
		left: rem($base-gap);
		z-index: 1005;
		width: min-content;
		background: $flat;
		backdrop-filter: blur(13px);
		border-radius: $base-border-radius;
		box-shadow: $box-shadow-soft;
		transform: translate(calc(-100% + (1.7em - #{rem($base-gap)})), 0);
		transition: .3s ease-in-out;
		display: flex;
		padding-right: 0;

		@include breakpoint($break, max) {
			transform: translate(-150%, 0);
		}

		ol {
			li {
				padding-top: 0.5em;
				padding-bottom: 0.5em;

				&:after {
					content: none;
				}
				&, a {
					white-space: nowrap;
				}
			}
		}

		label[for="table-of-contents-toggled"] {
			cursor: pointer;
			width: rem(32px);

			i {
				display: block;
				background: $primary;
				clip-path: $hexagon;
				color: $light;
				transition: .3s ease-in-out;
				font-size: rem(26px);
				width: 2.2em;
				height: 2.2em;
				line-height: 2.2em;
				text-align: center;
				margin: 0 -1.4em 0 auto;
			}
			&:after {
				content: '';
				position: absolute;
				inset: 35% .6em 35% auto;
				width: 2px;
				background: rgba($dark, .15);
				border-radius: 1em;
				transition: .3s ease-in-out;
			}

			&:hover {
				i,
				&:after {
					background: lighten($primary, 15%);
				}
			}
			&:active {
				i,
				&:after {
					background: lighten($primary, 20%);
				}
			}
			&:focus {
				i,
				&:after {
					background: lighten($primary, 25%);
				}
			}

			// @include breakpoint($break, max) {
			// 	grid-column: 2;
			// 	grid-row: 1 / 2;
				
			// 	i {
			// 		margin: 0 -1.3em .4em .4em;
			// 	}
			// 	&:after {
			// 		inset: 35% .6em 35% auto;
			// 		height: auto;
			// 		width: 2px;
			// 	}
				
			// }
		}
	}
	&:checked {
		~ #table-of-contents {
			transform: translate(0, 0);
		}
		~ #quickbar>div>*.table-of-contents label:before {
			opacity: 1;
			visibility: visible;
		}
	}
}