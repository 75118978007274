.phone,
.email {
	font-weight: 900;
}

.color-primary {
		color: $primary;
}
.color-light {
		color: $light;
}
.color-dark {
		color: $dark;
}

.weight-regular {
		font-weight: 400;
}
.weight-bold {
		font-weight: 900;
}

.margin-bottom {
		margin-bottom: rem($base-line-height);
}
.margin-bottom-double {
		margin-bottom: rem($base-line-height);

	@include breakpoint(tiny) {
		margin-bottom: rem($base-line-height * 2);
	}
}
.margin-bottom-big {
	margin-bottom: rem($base-line-height);

	@include breakpoint(tiny) {
		margin-bottom: rem($base-line-height * 2);
	}
	@include breakpoint(huge) {
		margin-bottom: rem(90px);
	}
}
.margin-bottom-gap {
		margin-bottom: rem($base-gap);
}
.margin-bottom-gap-double {
		margin-bottom: rem($base-gap * 2);
}
.margin-bottom-self {
		margin-bottom: 1em;
}
.margin-bottom-self-small {
		margin-bottom: 0.3em;
}
.margin-bottom-none {
		margin-bottom: 0;
}

.centered-base-gap-double {
	padding-left: rem($base-gap * 2);
	padding-right: rem($base-gap * 2);
}

.highlight {
		color: $secondary;
}

.btn {
	@extend %animatedTransform;
	background: $primary;
	border: none;
	color: $light;
	cursor: pointer;
	display: inline-block;
	font-size: rem($base-font-size);
	line-height: rem($base-line-height);
	font-weight: 700;
	padding: 1em 2em;
	text-align: center;
	text-decoration: none;
	font-family: $main-font;
	border-radius: $base-border-radius;
	box-shadow: $box-shadow-glow;

	&:hover {
		background: lighten($primary, 15%);
		color: $light;
	}
	&:active {
		background: lighten($primary, 20%);
		color: $light;
	}
	&:focus {
		background: lighten($primary, 25%);
		color: $light;
	}

	&.btn-light {
		background: $soft;
		color: $primary;
		box-shadow: none;

		&:hover {
			background: lighten($soft, 3%);
			color: lighten($primary, 15%);
			box-shadow: $box-shadow-soft;
		}
		&:active {
			background: lighten($soft, 6%);
			color: lighten($primary, 20%);
		}
		&:focus {
			background: lighten($soft, 9%);
			color: lighten($primary, 25%);
		}
	}
}

.font-tiny {
	font-size: rem(12px);
	line-height: 1.4em;
}
.font-small {
	font-size: rem(14px);
	line-height: 1.4em;
}
.font-base {
	font-size: rem($base-font-size);
	line-height: 1.4em;
}

.box {
	display: block;
	background: $blush;
	padding: rem($base-line-height);
	
	@include breakpoint(medium) {
		padding: rem($base-line-height) rem(30px);
	}
}
.box-big {
	padding: rem($base-line-height);
	
	@include breakpoint(medium) {
		padding: rem($base-line-height * 2);
	}
	@include breakpoint(giant) {
		padding: rem(90px);
	}
}

.border {
	border: $base-border;
}
.border-light {
	border: $base-border;
	border-color: $light;
}

.bg-primary {
		background: $primary;
}
.bg-blush {
		background: $blush;
}
.bg-cloud {
		background: $cloud;
}
.bg-soft {
		background: $soft;
}
.bg-light {
		background: $light;
}
.bg-smooth {
		background: $smooth;
}

.bg-gradient-light-top {
	background: linear-gradient(to bottom, $light, rgba($light, 0));
}
.bg-gradient-blush-top {
	background: linear-gradient(to bottom, $blush, rgba($blush, 0));
}

.bg-blur {
	backdrop-filter: blur(13px);
}


.bg-hexagon {
	position: relative;
	z-index: 1;

	&:after {
		content: '';
		position: absolute;
		inset: 0;
		background: image-url('layout/hexagon.svg') no-repeat center / contain;
		backdrop-filter: blur(13px);
		opacity: .04;
		z-index: -1;
	}
}



.border-radius {
	border-radius: $base-border-radius;
}
.border-radius-mid {
	border-radius: $base-border-radius-mid;
}
.border-radius-top-big {
	border-top-left-radius: clamp($base-border-radius-mid, 12vw, $base-border-radius-big);
	border-top-right-radius: clamp($base-border-radius-mid, 12vw, $base-border-radius-big);
}
.border-radius-bottom-big {
	border-bottom-left-radius: clamp($base-border-radius-mid, 12vw, $base-border-radius-big);
	border-bottom-right-radius: clamp($base-border-radius-mid, 12vw, $base-border-radius-big);
}
.border-radius-left-top-none {
	border-top-left-radius: 0;
}
.border-radius-left-bottom-none {
	border-bottom-left-radius: 0;
}
.border-radius-right-top-none {
	border-top-right-radius: 0;
}
.border-radius-right-bottom-none {
	border-bottom-right-radius: 0;
}

.box-shadow {
	box-shadow: $base-box-shadow;
}
.box-shadow-soft {
	box-shadow: $box-shadow-soft;
}
.box-shadow-glow {
	box-shadow: $box-shadow-glow;
}
.text-shadow {
	text-shadow: $base-box-shadow;
}
.text-shadow-glow {
	text-shadow: $box-shadow-glow;
}

.overflow {
		overflow: hidden;
}

.static {
		position: static;
}
.relative {
	position: relative;
}
.sticky {
	position: sticky;
	top: 20vh;
}

.contents {
		display: contents;
}
.inline {
		display: inline;
}
.inline-block {
		display: inline-block;
}
.flex {
	display: flex;
	flex-wrap: wrap;
}

.nowrap {
	flex-wrap: nowrap;
}
.font-nowrap {
	white-space: nowrap;
}

.content-columns {
	columns: 3 rem(369px);
	column-gap: clamp(rem($base-gap), 4vw, rem(101px));

	> * {
		break-inside: avoid;
	}
}

.gapped {
	gap: rem($base-gap);
}
.gapped-boxes {
	> * {
		width: clamp(rem(240px), 20vw, rem(369px));
		max-width: 90%;
		flex-grow: 1;

		@include breakpoint(tiny) {
			max-width: 50%;
		}
		@include breakpoint(full) {
			flex-grow: 0;
		}
	}
}

.height-100 {
	height: 100%;
}

.fit-contain {
	height: 100%;
	object-fit: contain;
}
.fit-cover {
	height: 100%;
	object-fit: cover;
}

.events-none {
	pointer-events: none;
}

.blend-darken {
	mix-blend-mode: darken;
}

.on-top {
	position: relative;
	z-index: 10;
}

.clear {
	clear: both;
	float: none;
}

.clearfix {
	@include pie-clearfix;
}

.hidden {
	display: none !important;
}

.hide-text {
	@include hide-text();
	display: inline-block;
	overflow: hidden;
	width: 0;
}

.float-left {
	float: left;
}

img.float-left {
	margin-right: 1rem;
}

.float-right {
	float: right;
}

img.float-right {
	margin-left: 1rem;
}

.fluid {
	height: auto;
	width: 100%;
}

.non-fluid {
	width: auto !important;
}

.align-left {
	text-align: left;
}

.align-right {
	text-align: right;
}

.align-center {
	text-align: center;
}

.wide {
	width: 100%;
	display: block;
}

/* 16:9 video resolutions */
.video-container,
.videoContainer {
	&:not(.videoTag) {
		position: relative;
		height: 0;
		overflow: hidden;
		padding-bottom: 56.25%;
		position: relative;

		iframe {
			border: none;
			height: 100%;
			position: absolute;
			width: 100%;
			top: 0;
			left: 0;
		}
	}

	video {
		width: 100%;
		height: auto;
	}
}
.googleMaps {
	@extend .videoContainer;

	@include breakpoint(medium) {
		height: rem(400px);
	}
}

body {
	&:before {
		$content: "default:" + $bp-context;
		width: str_length($content) * 6pt + $base-gap * 2;

		@each $point, $width in $grid-map {
			$content: $content + "...." + $point + ":" + nth(map-get(map-get($grid-map, $point), width), 1);

			@include breakpoint($point) {
				width: str_length($content) * 6pt + $base-gap * 2;
			}
		}

		content: "#{$content}";
		display: none !important; /* Prevent from displaying. */
	}

	&.devmode:before,
	&.debug:before {
		background: $alert;
		border-color: $alert;
		border-style: solid;
		border-width: 0 rem($base-gap);
		color: $light;
		display: block !important;
		font-family: Courier;
		font-size: 10pt;
		left: 0;
		line-height: 2.5;
		overflow: hidden;
		position: absolute;
		right: 0;
		text-align: center;
		top: 0;
		white-space: nowrap;
		z-index: 99999;
	}
	&:hover:before {
		opacity: 0.5;
		pointer-events: none;
	}
}

// Animations
%animatedTransform {
	transition: 0.3s;
}

html.inProgress {
	pointer-events: none;
	&:before {
		content:"";
		width: 100vw;
		height: 100vh;
		background:rgba(0,0,0,0.5);
		position: fixed;
		top:0;
		left:0;
		z-index:99999998
	}
	&:after {
		content:"";
		position: fixed;
		top: 0;
		height: 0;
		background: image-url('loading.svg') no-repeat center center;
		height: 100vh;
		width: 100vw;
		z-index:99999999;
		filter:invert(100%);
	}
}