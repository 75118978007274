// Relativ path to images folder (used in the image-url() / img-url() function)
$image-path: "/wp-content/themes/vertical-22-12/images/" !default;

$break: large; // Breakpoint – switch to Desktop version

// +++++ Font settings
// Font size of main content (in px)
$base-font-size: 16px;

// Line height of main content (in px)
$base-line-height: 25px;

// Headline font sizes (in px)
$h1-size: 75px;
$h2-size: 50px;
$h3-size: 35px;
$h4-size: 26px;
$h5-size: 20px;
$h6-size: 18px;

// Font stacks: $main-font for simple content, $display-font for headlines etc., $icon-font for icons
$main-font:    "Lato", sans-serif;
$display-font: "Lato", sans-serif;
$icon-font:   "icomoon";

// +++++ Design & Layout
// Colours for your design.
$primary:   #51267B;
$secondary: #8348BC;
$tertiary:  #B472F5;
$light:     #fff;
$medium:    #807D83;
$dark:      #2D2733;
$border:    #DBE0E2;
$alert:     #F8C44F;
$soft:      #F7F6F7;
$cloud:      darken($light, 1%);
$blush:     #FAF9FB;
$info:      #99E2F2;
$success:   #A2BC0C;
$toned:     rgba($primary, 0.05);
$smooth:    rgba($dark, 0.02);
$flat:      rgba($light, 0.8);
$shine:     linear-gradient(270deg, #E4C8FF 0%, #8348BC 49.48%, #E9D3FF 100%);
$black:     #000;


// Horizontal padding left and right of grid columns
$base-gap: 18px;

// Grid row max-width
$row-max-width: 1620px;

// Grid columns
$column-count: 12;

// Standard border for your design
$base-border: 1px solid $border;

$base-border-radius: rem(5px);
$base-border-radius-mid: rem(20px);
$base-border-radius-big: rem(90px);

$base-box-shadow: rem(-5px) rem(5px) rem(24px) rgba($black, 0.25);
$box-shadow-soft: rem(-10px) rem(10px) rem(54px) rgba($black, 0.06);
$box-shadow-glow: 0 0 rem(30px) rgba(#8100FF, 0.2);

$hexagon: polygon(50% 0, 94% 25%, 94% 75%, 50% 100%, 6% 75%, 6% 25%);

// Standart outline styles for focus states
$outline: 1px dotted $alert;

// Assume a different base font size for breakpoint em calculation (default: 16px)
$bp-context: 16px;

// +++++ Miscellaneous
// text-indent value when using hide-text(): "right" = positive value, "left" = negative value
$hide-text-direction: "right";

$icon-map: (
    a: "\e922",
    add-user: "\e923",
    arrow-down: "\e924",
    arrow-left: "\e925",
    arrow-right: "\e926",
    arrow-up: "\e927",
    bubble: "\e928",
    chevron-double: "\e929",
    chevron-down: "\e901",
    clock: "\e902",
    exclamation: "\e903",
    filter: "\e904",
    gear: "\e905",
    headset: "\e900",
    hexagon: "\e906",
    home: "\e907",
    laptop: "\e92b",
    lock: "\e909",
    map-marker: "\e90a",
    pen: "\e90b",
    phone: "\e90c",
    plus: "\e90d",
    question: "\e90e",
    saved: "\e908",
    scale: "\e90f",
    search: "\e910",
    security: "\e91e",
    star-empty: "\e91f",
    star: "\e920",
    times: "\e921",
    virtual-desktop: "\e911",
    list-check: "\e912",
    cloud-ressources: "\e913",
    network: "\e914",
    file: "\e915",
    print: "\e916",
    envelope-check: "\e917",
    teams: "\e918",
    call: "\e919",
    user: "\e91a",
    cloud-check: "\e91b",
    list: "\e91c",
    access: "\e91d",
    device: "\e92a",
);

$grid-map: (
    default: (
        width: em(0px, $bp-context),
        cols: (1,2,3,4,5,6,7,8,9,10,11,12),
        center: (justify-content: center),
        between: (justify-content: space-between),
        around: (justify-content: space-around),
        end: (justify-content: flex-end),
        middle: (align-items: center),
        bottom: (align-items: flex-end),
        align-right: (text-align: right)
    ),
    micro: (
        width:  em(360px, $bp-context),
        cols: (1,2,3,4,5,6,7,8,9,10,11,12)
    ),
    tiny: (
        width:  em(480px, $bp-context),
        cols: (1,2,3,4,5,6,7,8,9,10,11,12),
        prefix: (4),
        suffix: (4),
        start: (justify-content: flex-start)
    ),
    small: (
        width:  em(640px, $bp-context),
        cols: (1,2,3,4,5,6,7,8,9,10,11,12),
        prefix: (7),
        suffix: (7),
        align-center: (text-align: center),
        last: (order: 1)
    ),
    medium: (
        width:  em(760px, $bp-context),
        cols: (1,2,3,4,5,6,7,8,9,10,11,12),
        prefix: (1,2,6),
        suffix: (1,6),
        middle: (align-items: center),
        center: (justify-content: center),
        end: (justify-content: flex-end),
        align-center: (text-align: center)
    ),
    large: (
        width:  em(992px, $bp-context),
        cols: (1,2,3,4,5,6,7,8,9,10,11,12),
        center: (justify-content: center),
        start: (justify-content: flex-start),
        align-left: (text-align: left),
        last: (order: 1)
    ),
    giant: (
        width:  em(1200px, $bp-context),
        cols: (1,2,3,4,5,6,7,8,9,10,11,12),
        prefix: (8),
        suffix: (1,8),
        align-right: (text-align: right),
        align-left: (text-align: left),
        between: (justify-content: space-between)
    ),
    huge: (
        width:  em(1340px, $bp-context),
        cols: (1,2,3,4,5,6,7,8,9,10,11,12)
    ),
    between: (
        width:  em(1440px, $bp-context),
        cols: (1,2,3,4,5,6,7,8,9,10,11,12),
        align-left: (text-align: left)
    ),
    full: (
        width:  em(1520px, $bp-context),
        cols: (1,2,3,4,5,6,7,8,9,10,11,12),
        suffix: (1),
        align-left: (text-align: left),
        center: (justify-content: center)
    ),
    grid: (
        width:  em(1620px, $bp-context),
        cols: (1,2,3,4,5,6,7,8,9,10,11,12)
    ),
    big: (
        width:  em(1840px, $bp-context),
        cols: (1,2,3,4,5,6,7,8,9,10,11,12)
    )
);