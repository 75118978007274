$nav-height: calc(100vh - #{rem($bar-height)});

@mixin page-navi($class: "#navigation") {
  #{$class} {
    transition: .3s ease-in-out;
    position: fixed;
    max-width: 100%;
    right: 0;
    transform: translateX(100%);
    @content;
  }
}
@mixin ul {
  ul {
    width: 100%;
    @content;
  }
}
@mixin nav-buttons {
  a,
  &:not(.nav-back) > span,
  &.nav-back,
  &.menu-item-has-children > span {
    @extend %animatedTransform;
    display: block;
    cursor: pointer;
    @content;
  }
  &.current-menu-item,
  &.current-menu-parent,
  &.active {
    > a, > span {
      @extend %button-active;
    }
  }
  &:hover {
    > a,
    &.menu-item-has-children > span {
      @extend %button-hover;
    }
  }
}
@mixin has-sub($class: ".menu-item-has-children") {
  &#{$class} {
    > span,
    > a {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @content;

      &:after {
        @extend %next-icon;
        margin-left: 0.5em;
        display: inline-block;
      }
    }
    &.current-menu-item,
    &.current-menu-parent,
    &.active {
      > a,
      > span {
        @extend %button-active;
      }
    }
    @content;
  }
}
@mixin sub($class: ".sub-menu") {
  #{$class} {
    transition: transform 0.3s;
    position: absolute;
    left: 0;
    top: 0;
    @content;

    @include breakpoint($break, max) {
      transform: translateX(100%);
    }
    @include breakpoint($break) {
      opacity: 0;
      visibility: hidden;
    }
    &.current {
      @include breakpoint($break, max) {
        transform: translateX(0%);
      }
      @include breakpoint($break) {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
@mixin nav-back {
  &.nav-back {
    position: relative;
    @content;

    &:before {
      @extend %prev-icon;
      margin-right: 1em;
      display: inline-block;
    }
  }
}

@include breakpoint($break, max) {
  #navigation {
    min-width: 50%;
    overflow: hidden;

    @if $bar-pos == "top" {
      top: rem($bar-height);
      bottom: 0;
    } @else if $bar-pos == "bottom" {
      top: 0;
      bottom: rem($bar-height);
    }
    &[data-depth="0"],
    &.first-level {
      nav {
        height: $nav-height;
        overflow: hidden;
        overflow-y: auto;
      }
    }
    nav {

      ul {
        
        &.navi {
          li {
            &.menu-item-has-children {
              .sub-menu {
                height: $nav-height;
                
                &.open {
                  -webkit-overflow-scrolling: touch;
                  overflow: hidden;
                  overflow-y: auto;
                }
                &:before {
                  content: attr(data-parent-name);
                  display:block;
                }
              }
            }
          }
        }
      }
    }
  }
  html.hidden-scroll {
    overflow: hidden;
  }
}

@mixin desktop-version-functionality {
  #navigation {
    top: 0;
    width: 100%;

    nav {
      ul {
        &.navi {
          display: flex;
          justify-content: space-between;

          li {
            a,
            &:not(.nav-back) > span,
            .sub-menu:before,
            .nav-back {
              white-space: nowrap;
            }
            &.menu-item-has-children {
              position: relative;

              .sub-menu {
                top: 100%;
                width: auto;

                .sub-menu {
                  left: 100%;
                  top: 0;
                }
              }
            }
            &.nav-back {

              &:before {
                content: $close-icon;
                text-align: center;
                width: 100%;
              }
              span {
                display: none;
              }
            }
          }
        }
      }
    }

    &.hoverNavigation nav ul.navi li.nav-back {
      display:none;
    }
  }
}

#quickbar {
  position: fixed;
  left: 0;
  width: 100%;

  @include breakpoint($break, max) {
    height: rem($bar-height);
  }

  @if $bar-pos == "top" {
    top: 0;

    @if $bar-hide {
      &.hide {
        transform: translateY(-100%);
      }
    }
  } @else if $bar-pos == "bottom" {
    bottom: 0;

    @if $bar-hide {
      &.hide {
        transform: translateY(100%);
      }
    }
  }

  label[for="navi-toggled"] {
    display: block;
    cursor: pointer;
    width: rem(36px);
    height: rem(36px);
    position: relative;
    overflow: hidden;

    > span {
      width: 100%;
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      align-content: center;
      justify-content: flex-end;

      span {
        display: none;
      }

      &:before,
      &:after {
        content: "";
        display: block;
        background: $bar-color;
        height: 1px;
        margin: 0.22rem 0;
        transform-origin: center;
        border-radius: 1rem;
        width: rem(36px);
      }
      &:before {
        transition: width 0.5s 0.4s ease-in-out, transform 0.4s ease-in-out, background 0.6s ease-in-out;
      }
      &:after {
        transition: width 0.4s 0.55s ease-in-out, transform 0.4s ease-in-out, background 0.6s ease-in-out;
        width: rem(25px);
      }
    }
  }
}

#navi-toggled {
  display: none;

  &:checked {
    ~ * #navigation,
    ~ #navigation {
      @include breakpoint($break, max) {
        transform: none;
      }
    }
    ~ #quickbar {
      label[for="navi-toggled"] {
        > span {
          &:before,
          &:after {
            transition: width 0.4s 0.2s ease-in-out, transform 0.4s 0.6s ease-in-out, background 0.6s ease-in-out;
          }
          &:before {
            transform: translateY(0.27rem) rotate(-45deg);
            width: rem(20px);
          }
          &:after {
            transform: translateY(-0.27rem) rotate(45deg);
            width: rem(20px);
          }
        }
      }
    }
  }
}
