
.icon {
  &:before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    text-rendering: auto;
    line-height: 1;
    font-family: $icon-font;
  }
  &.icon-33 {
    &:before {
      font-size: rem(33px);
      display: block;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }
}

@each $name, $ico in $icon-map {
  // .icon-#{$name}
  .icon-#{$name}:before {
    content: "#{$ico}";
  }
}