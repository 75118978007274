* {
	margin: 0;
	padding: 0;

	&,
	&:before,
	&:after {
		box-sizing: inherit;
	}
}

::selection {
}
::-moz-selection {
}

// DO NOT set font-size and line-height here!
// Adjust $base-font-size and $base-line-height in config
html {
	background: $light;
	box-sizing: border-box;
	color: $dark;
	font-family: $main-font;
	font-size: 100% * ($base-font-size / 16px);
	hyphens: manual;
	line-height: rem($base-line-height);
	scroll-behavior: smooth;

	/* disable text resize in landscape. e.g. on iphone */
	text-size-adjust: none;
	
	@include breakpoint(large, max) {
		font-size: 100% * (($base-font-size - 4) / 16px);
	}
	@include breakpoint($break) {
		scroll-padding-top: rem(97px);
	}
}

body {
	line-height: rem($base-line-height);
	opacity: 1 !important;
	overflow-anchor: none;
	&:not(.javascript) * {
		transition: none !important;
	}
}

iframe,
[data-iframe] {
	border: none;
	width: 100%;
}

address {
	font-style:normal;
}

strong, b {
	font-weight: 900 !important;
}

video {
	width: 100%;
	aspect-ratio: 21 / 9;
	object-fit: cover;
}

/**
 * Headlines
 */

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	margin-bottom: 1em;
	display: block;
	text-rendering: optimizeLegibility;
	hyphens: manual;
	line-height: 1.3em;
	font-weight: 400;

	@include breakpoint(small, max) {
		br {
			display: none;
		}
	}

	&.icon {
		&:before {
			margin-right: 0.5em;
		}
	}
}

h1, h2, h3,
.h1, .h2, .h3 {
	color: $primary;
}

h4, h5, h6,
.h4, .h5, .h6 {
	line-height: 1.4em;
}

h1,
.h1 {
	font-size: clamp(rem(32px), 4.5vw, rem($h1-size));
}

h2,
.h2 {
	font-size: clamp(rem(29px), 4vw, rem($h2-size));
}

h3,
.h3 {
	font-size: clamp(rem(25px), 3.5vw, rem($h3-size));
}

h4,
.h4 {
	font-size: clamp(rem($base-font-size), 3vw, rem($h4-size));
	line-height: 1.4em;
}

h5,
.h5 {
	font-size: clamp(rem($base-font-size), 2.5vw, rem($h5-size));
	line-height: 1.4em;
}

h6,
.h6 {
	font-size: rem($h6-size);
	line-height: 1.4em;
}

/**
 * Links
 */
a {
	color: $primary;
	word-wrap: break-word;
	text-decoration: none;
	transition: 0.3s ease-in-out;

	&:hover {
		color: lighten($primary, 15%);
	}
	&:active {
		color: lighten($primary, 20%);
	}
	&:focus {
		color: lighten($primary, 25%);
	}

	img {
		border: none;
	}

	// &[href^="tel"] {
	// 	color: inherit;
	// }

	&.line-link:not(.btn) {
		position: relative;
		display: inline-block;
		padding: 0.1em 0;
		z-index: 1;

		&:after {
			@extend %animatedTransform;
			content: '';
			position: absolute;
			// border-bottom: $base-border;
			// border-color: $primary;
			height: 2px;
			// height: 0.17em;
			border-radius: 1rem;
			left: 0;
			right: 0;
			bottom: 0;
			background: $primary;
			z-index: -1;
		}

		&:hover {
			color: lighten($primary, 15%);

			&:after {
				background: lighten($primary, 15%);
				left: -0.2em;
				right: -0.2em;
			}
		}
		&:active {
			color: lighten($primary, 20%);
			
			&:after {
				background: lighten($primary, 20%);
				left: -0.2em;
				right: -0.2em;
			}
		}
		&:focus {
			color: lighten($primary, 25%);
			
			&:after {
				background: lighten($primary, 25%);
				left: -0.2em;
				right: -0.2em;
			}
		}
	}
}

a:not(.btn):focus,
[tabindex]:focus {
	outline: $outline;
	outline-offset: rem(5px);
}

hr,
.hr {
	display: flex;
	background: $medium;
	border: none;
	clear: both;
	height: rem(1px);
	margin: 1rem 0;
	flex-basis: 100%;
}

ul,
ol {
	list-style: none;

	&.styled-list {
		li {
			padding: 0.25em 0;
			padding-left: 1.2em;
			position: relative;

			&:before {
				@extend .icon;
				@extend .icon-hexagon;
				color: $primary;
				text-indent: -1.8em;
				font-size: 0.7em;
			}

			&:not(:last-child) {
				&:after {
					content: '';
					position: absolute;
					border-left: $base-border;
					height: calc(100% - 1.2em);
					bottom: 0;
					left: 0.25em;
					transform: translate(0, .5em);
				}
			}
		}

		ul {
			margin-left: 1rem;
			margin-bottom: 0;
		}
	}
}

ol {
	&.styled-list
	{
		counter-reset: item;
	
		> li {
			counter-increment: item;
	
			&:before {
				content: counter(item) ". ";
				font-family: $main-font;
				font-weight: 900;
				font-size: 1em;
				text-indent: -1.5em;
			}
			&:after {
				content: none !important;
			}
		}
		ol {
			counter-reset: item;
			margin-bottom: 0;
	
			> li {
				&:before {
					margin-right: rem(5px);
				}
			}
		}
	}
}

dl {
	&.styled-list {
		@include pie-clearfix;

		dt,
		dd {
			display: block;
			background: $cloud;
			padding: .8em 1.1em;
		}

		dt {
			padding-bottom: 0;
			font-weight: 900;
			border-radius: $base-border-radius $base-border-radius 0 0;
		}

		dd {
			padding-top: 0;
			border-radius: 0 0 $base-border-radius $base-border-radius;

			+ dt {
				margin-top: rem($base-line-height);
			}

			+ dd {
				margin-top: -.8em;
			}
		}
	}

	&.float-list {
		@include pie-clearfix;
		display: grid;
		grid: auto / 1fr 5fr;

		dt,
		dd {
			display: block;
		}

		dt {
			padding-right: 0.5rem;
			width: 40%;
			clear: left;
			font-weight: 900;
		}

		dd {
			width: 60%;
		}
	}
}

/*
 * responsive images
 */

img {
	vertical-align: bottom;
	float: none;
	max-width: 100%;
	width: 100%;
	height: auto;
	
	// &:not([width]):not([height]) {
	// }

	&[src^='http://cms.'], &[src^='https://cms.'] {
		max-width: none;
		width: auto;
	}

	[data-rel] &,
	.lightbox-image & {
		margin-bottom: 0;
	}

	&[data-src] {
		@extend %lazy-loading;
	}
}

mark,
.mark {
	background: $soft;
	color: $primary;
	display: inline-block;
	margin-right: 0.3em;
	margin-bottom: 0.2em;
	padding: 0.3em 0.5em;
	padding-left: 1.8em;
	border-radius: $base-border-radius;
	font-size: rem(12px);
	line-height: 1.2em;

	&.icon,
	.icon {
		&:before {
			font-size: 1.2em;
			font-weight: 700;
			text-indent: -1.2em;
			vertical-align: -0.08em;
		}
	}
}

/**
 * Tables
 */
main {
	table {
		display: table;
		border-collapse: collapse;
		width: 100%;
		max-width: 100%;
		text-align: left;
		font-size: rem($base-font-size);

		@include breakpoint(small, max) {
			overflow-x: auto;
			display: block;
		}

		caption {
			text-align: left;
			padding: rem($base-gap) rem($base-gap) rem(25px);
			display: table-caption;
			font-weight: bold;
			font-size: 1.2rem;
		}

		thead {
			border-collapse: collapse;
			display: table-header-group;
		}

		tbody {
			width: 100%;
			overflow-x: scroll;
			display: table-row-group;

			tr {
				padding: rem($base-gap) 0;

				&:not(:last-child) {
					border-bottom: rem(1px) solid $medium;
				}
			}
		}

		tr {
			display: table-row;
		}

		th,
		td {
			padding: rem($base-gap);
			display: table-cell;
			vertical-align: top;
		}

		@include breakpoint(small, max) {
			td {
				min-width: 50vw;
			}
		}
	}
}