#header,
#content,
#footer {
	position: relative;
	display: block;
}

#page-wrap {
	@include breakpoint($break) {
		padding-top: rem(97px);
	}

	.row {
		&:not(.full-width) {
			max-width: clamp(2rem, 86vw, rem($row-max-width));
		}
	}
}

// HEADER
//////////////////////////////

#header {
	overflow: hidden;

	#branding {
		a {
			display: block;
			max-width: 100%;
			height: auto;
			position: relative;
			z-index: 1;
			padding: clamp(1rem, 5vw, rem($base-line-height * 2)) 0;

			&:hover {
				svg path {
					fill: #7738b5;
				}
			}
			
			svg {
				display: block;
				width: rem(200px);
				margin: 0 auto;

				path {
					transition: .3s ease-in-out;
				}
			}

			@include breakpoint($break) {
				padding: 0;
				position: fixed;
				top: 0;
				height: rem(97px);
				display: flex;
				align-items: center;
				z-index: 2000;
				left: rem(30px);

				svg {
					width: clamp(rem(130px), 9vw, rem(200px));
				}
			}
			@include breakpoint(cut) {
				left: 7vw;
			}
		}
	}
	#management-app {
		background: $light url('/wp-content/themes/vertical-22-12/images/header/cubic.svg') no-repeat center bottom / auto 140%;
		padding-bottom: 5rem;

		@include breakpoint(medium) {
			background-size: 100% auto;
			background-position: center bottom;
			padding: 0;
		}
	}
	.app-img {
		display: block;
		max-width: clamp(17rem, 55vw, 27rem);
		margin: calc(-27rem - 22vw) calc(-15rem + 7vw) 0 auto;
		transform: rotate(-20deg);
		position: relative;
		z-index: 1;

		@include breakpoint(small) {
			margin: calc(-46rem + 7vw) calc(-17rem + 7vw) 0 auto;
		}
		@include breakpoint(medium) {
			position: absolute;
			bottom: calc(13rem - 25vw);
			left: 55%;
			transform: translate(-50%, 0) rotate(0deg);
			z-index: 10;
			margin: 0;
			max-width: clamp(18rem, 27vw, 100%);
		}
		@include breakpoint(large) {
			bottom: calc(13rem - 21vw);
		}
		@include breakpoint(giant) {
			max-width: clamp(18rem, 30vw, 100%);
			bottom: 0;
		}
		@include breakpoint(cut) {
			max-width: 100%;
		}
	}
	#partner {
		height: clamp(rem(180px), 15vw, rem(200px));
		padding: clamp(rem(20px), 4vw, rem(70px)) 0;

		.swiper-wrapper {
			transition-timing-function: linear;
		}
		.swiper-slide {
			box-sizing: border-box;
			margin: 0 rem(45px);
			width: clamp(rem(150px), 15vw, rem(230px));
		}

		img[src*="microsoft"] {
			mix-blend-mode: darken;
		}
	}


	.cta-btns {
		@include breakpoint(big) {
			flex-wrap: nowrap;
		}
		
		.btn {
			white-space: nowrap;
		}
	}

	#kununu-header {
		width: auto;
		height: 8.4rem;
		float: left;
		margin-right: 2rem;

		@include breakpoint(medium) {
			margin-bottom: 5rem;
			height: 8rem;
		}
	}
}

// MAIN CONTENT
//////////////////////////////

#content {
	body:not(.home) & {
		> *:last-child {
			padding-bottom: clamp(5rem, 15vw, 7rem);
		}
	}
	
	p,
	ol,
	ul,
	dl,
	table {
		&:not(.margin-bottom-none):not(.margin-bottom-double):not(.margin-bottom-big) {
			@extend .margin-bottom;
		}
	}
	
	.content {
		ul:not(.unstyled-list),
		dl:not(.unstyled-list):not(.float-list),
		ol:not(.unstyled-list) {
			@extend .styled-list;
		}
		a:not(.btn):not(.box) {
			@extend .line-link;
		}
	}

	img {
		margin-bottom:rem($base-gap*2);
	}
}

#warum-vertical {
	.warum-vertical {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		z-index: 0;
		pointer-events: none;
		padding-bottom: 9.8rem;
	
		@include breakpoint(large) {
			padding-top: 15rem;
		}
		@include breakpoint(giant) {
			padding-top: 17rem;
			padding-bottom: 12rem;
		}
		@include breakpoint(full) {
			padding-top: 16rem;
		}
		
		svg {
			display: block;
			position: sticky;
			top: 50vh;
			margin: 0 auto;
			filter: drop-shadow($box-shadow-glow);
			transition: 2s linear;
			transform-origin: center bottom;
	
			@include breakpoint(full, max) {
				width: 15vw;
				height: calc(15vw / (486/548));
			}
			@include breakpoint(large, max) {
				width: 50vw;
				height: calc(50vw / (486/548));
				opacity: .1;
			}
			@include breakpoint(small, max) {
				display: none;
			}
		}
	}
	#trust {
		z-index: 2;

		&:after {
			content: '';
			position: absolute;
			inset: 0;
			background: image-url('layout/hexagon.svg') no-repeat center / contain;
			backdrop-filter: blur(13px);
			opacity: .04;
			z-index: -1;
		}
		
		&.isOnScreen + .warum-vertical svg {
			@include breakpoint(small) {
				scale: 0;
			}
			@include breakpoint(giant) {
				scale: none;
				width: 8rem;
				height: calc(8rem / (486/548));
			}
		}

		.vStop {
			white-space: nowrap;
			font-size: clamp(rem(29px), 4vw, rem(54px));
			line-height: 1.3em;

			@include breakpoint(giant) {
				padding-left: 2.3em;
    			padding-right: 0.1em;
			}

			.hidden {
				@include breakpoint(giant, max) {
					display: inline !important;
				}
			}
		}
	}
}
#services {
	.row.countingService {
		counter-reset: countS;
	
		.col {
			counter-increment: countS;
			position: relative;
			z-index: 1;
	
			// @include breakpoint(medium, max) {
			// 	padding-bottom: 4rem;
			// }
			// @include breakpoint(small) {
			// 	&:not(:last-child) {
			// 		margin-bottom: -2rem;
			// 	}
			// }
	
			&:after {
				content: counters(countS, ".") ". ";
				display: block;
				position: absolute;
				right: rem($base-gap * 2);
				font-size: rem(80px);
				line-height: 1;
				font-weight: 900;
				color: $toned;
				z-index: -1;
				top: -1.5rem;
	
				// @include breakpoint(giant) {
				// 	top: 50%;
				// 	right: 0;
				// 	transform: translate(80%, -50%);
				// }
			}
	
			&:nth-child(odd) {
				// @include breakpoint(giant) {
				// 	padding-right: rem(65px);
				// }
			}
			&:nth-child(even) {
				// @include breakpoint(giant) {
				// 	padding-left: rem(65px);
				// }
	
				&:after {
					right: auto;
					left: rem($base-gap * 2);
					
					// @include breakpoint(giant) {
					// 	left: 0;
					// 	transform: translate(-80%, -50%);
					// }
				}
			}
		}
	}
}

.teaser {
	.searchform {
		position: relative;
		max-width: rem(506px);
		margin: 0 auto;

		input {
			background: $light;
			padding-right: 6em;

			@include breakpoint(tiny) {
				padding-right: 13em;
			}
		}
		button {
			position: absolute;
			inset: .3em .3em .3em auto;

			@include breakpoint(tiny, max) {
				padding: 1em 1.15em;
				
				span {
					display: none;
				}
			}

			i {
				font-size: 1.2em;
				font-weight: 700;

				@include breakpoint(tiny) {
					margin-left: .2em;
					vertical-align: -.05em;
				}
			}
		}
	}
}
.teasers {
	counter-reset: teaser-count;
	
	li {
		counter-increment: teaser-count;

		a {
			display: flex;
			flex-flow: column nowrap;
			position: relative;
			z-index: 1;
			gap: rem($base-line-height / 2);

			&:before {
				content: counters(teaser-count, ".") ". ";
				display: block;
				position: absolute;
				font-size: rem(80px);
				line-height: 1;
				font-weight: 900;
				color: $toned;
				z-index: -1;
				top: rem($base-line-height / 2);
				left: rem($base-gap);
				font-family: $main-font;
			}

			* {
				margin-bottom: 0 !important;
			}
			p {
				flex-grow: 1;
			}
			&:hover, 
			&:active, 
			&:focus {
				box-shadow: 0 1.5rem 5rem -2rem rgba($black, 0.3);
				transform: translate(0, -5%);
				z-index: 2;
			}
			&:hover {
				background: lighten($cloud, 3%);
				// color: lighten($primary, 10%);
			}
			&:active {
				background: lighten($cloud, 6%);
				// color: lighten($primary, 15%);
			}
			&:focus {
				background: lighten($cloud, 9%);
				// color: lighten($primary, 20%);
			}
		}
	}
}

#jobs-list {
	
	.filter {
		&:after {
			content: image-url('layout/hexagon.svg');
			position: absolute;
			opacity: .05;
			z-index: -1;
			backdrop-filter: blur(13px);
			bottom: rem($base-line-height);
			left: rem($base-gap);
			transform: translate(-50%, 50%);
			width: 18rem;
			height: 18rem;
		}
		
		.attention {
			
			@include breakpoint(small) {
				position: relative;
			}

			#recruiting-video {
				position: relative;
				z-index: 1;
				border-radius: $base-border-radius-mid !important;
				box-shadow: $base-box-shadow;
				margin: -2rem 0 0 -2rem;
			}
			#kununu-2024 {
				position: absolute;
				right: 2rem;
				top: -2rem;
				z-index: 2;
				width: auto;
				box-shadow: $base-box-shadow;
				transform: translate(20%, -50%);
				height: 75%;

				@include breakpoint(large) {
					transform: translate(20%, -25%);
				}
			}
		}
		
		@include breakpoint(small) {
			padding-right: 0;

			.sticky-cont {
				box-shadow: $box-shadow-soft;
				background: $light;
				border-start-start-radius: $base-border-radius-mid;
				border-end-start-radius: $base-border-radius-mid;
			}
		}

		.category_select_group {
			overflow: hidden;
			transition: .3s ease-in-out;
		}

		@include breakpoint(small, max) {
			height: 4.3rem + rem(130px + 9);
			
			.sticky-cont {
				position: absolute;
				inset: 0 0 13.8rem; 
				z-index: 11;
				padding-top: rem(130px + 9);
				visibility: hidden;

				.attention {
					position: absolute;
					top: 0;
					left: 50%;
					transform: translateX(-50%);
					width: auto;
					height: rem(130px);

				}
				#recruiting-video {
					margin: 0;
					height: 100%;
					width: auto;
				}
				#kununu-2024 {
					transform: translate(20%, 0);
					height: rem(70px);
				}
				#recruiting-video,
				#kununu-2024 {
					visibility: visible;
				}
			}
			.sticky {
				top: 0;
				padding: 0;
				box-shadow: $box-shadow-soft;
				visibility: visible;

				[for="career-select"] {
					padding: rem($base-line-height);
					margin: 0;
				}
			}
			.category_select_group {
				gap: rem($base-gap);
				padding: 0 rem($base-line-height) rem($base-gap / 2);
				
				@include breakpoint(tiny) {
					display: grid;
					grid: auto / 1fr 1fr;
				}

				.h5 {
					grid-column: 1 / 3;
				}
			}
		}
		
		.sticky-cont {
			@include breakpoint(small) {
				height: 100%;
			}
		}
		.border-radius-mid {
			@include breakpoint(small) {
				border-end-end-radius: 0;
				border-start-end-radius: 0;
			}
		}
	}
	#career-select {
		display: none;

		&:checked {
			~ .category_select_group {
				@include breakpoint(small, max) {
					opacity: 0;
					visibility: hidden;
					max-height: 0;
				}
			}
		}
	}
	.jobs {
		border-start-start-radius: 0;

		@include breakpoint(small, max) {
			border-start-end-radius: 0;
		}
		@include breakpoint(small) {
			border-end-start-radius: 0;
		}
	}
	article:not(.hiding) {
		position: relative;
		transition: .3s ease-in-out;
		
		&:hover, &:focus, &:active {
			box-shadow: 0 1.5rem 5rem -2rem rgba($black, 0.3);
			transform: translate(0, -5%);
			z-index: 2;
		}
	}
}

#contact {
	padding-bottom: clamp(5rem, 15vw, 7rem);
	
	.font-nowrap {
		@include breakpoint(small, max) {
			white-space: normal;
		}
	}
}

body.privacy-policy main,
body.single-post {
	h2, h3, h4, h5, h6,
	.h2, .h3, .h4, .h5, .h6 {
		font-weight: 900;
	}
	
	h2,
	.h2 {
		font-size: clamp(rem(29px), 4vw, rem($h2-size - 30));
	}

	h3,
	.h3 {
		font-size: clamp(rem(25px), 3.5vw, rem($h3-size - 30));
	}

	h4,
	.h4 {
		font-size: clamp(rem($base-font-size), 3vw, rem($h4-size - 30));
		line-height: 1.4em;
	}
}
body.privacy-policy #content a {
	display: inline !important;
	text-decoration: underline;

	&:after {
		content: none !important;
	}
}


// HEXAGONS
//////////////////

@include breakpoint(huge) {
	.hexagon-double-top {
		position: relative;
		z-index: 1;
	
		&:before,
		&:after {
			content: image-url('layout/hexagon.svg');
			position: absolute;
			opacity: .05;
			z-index: -1;
			backdrop-filter: blur(13px);
		}
		&:before {
			inset: 3rem 6% auto 60%;
		}
		&:after {
			inset: 35rem -5% auto 84%;
		}
	}
	.hexagon-double-left {
		position: relative;
		z-index: 1;
	
		&:before,
		&:after {
			content: image-url('layout/hexagon.svg');
			position: absolute;
			opacity: .05;
			z-index: -1;
			backdrop-filter: blur(13px);
		}
		&:before {
			inset: 12rem 82% auto -8%;
		}
		&:after {
			inset: 35rem 61% auto 6%;
		}
	}
	.hexagon-double-middle {
		position: relative;
		z-index: 1;
	
		&:before,
		&:after {
			content: image-url('layout/hexagon.svg');
			position: absolute;
			opacity: .05;
			z-index: -1;
			backdrop-filter: blur(13px);
		}
		&:before {
			inset: 26rem 47% auto 27%;
		}
		&:after {
			inset: 49rem 61% auto 6%;
		}
	}
}


// AREA BACKGROUNDS
//////////////////////////////

.blurs-cont {
	position: relative;
	z-index: 1;

	&.lazy-bg {
		&#warum-vertical {
			&.lazy-loaded {
				background: image-url('layout/small-benefits.jpg') no-repeat center bottom / cover;

				@include breakpoint(small) {
					background-image: image-url('layout/giant-benefits.jpg');
				}
				@include breakpoint(giant) {
					background-image: image-url('layout/benefits.jpg');
				}
			}
		}
		&.honey-bg {
			&.lazy-loaded {
				background: image-url('layout/small-honey-white.jpg') no-repeat center bottom / 100% auto;

				@include breakpoint(small) {
					background-image: image-url('layout/giant-honey-white.jpg');
				}
				@include breakpoint(giant) {
					background-image: image-url('layout/honey-white.jpg');
				}
			}
		}
		&#jobs-list {
			&.lazy-loaded {
				background: image-url('layout/small-jobs-list.jpg') no-repeat center / cover;

				@include breakpoint(small) {
					background-image: image-url('layout/giant-jobs-list.jpg');
				}
				@include breakpoint(giant) {
					background-image: image-url('layout/jobs-list.jpg');
				}
			}
		}
		&#contact {
			&.lazy-loaded {
				background: image-url('layout/small-contact.jpg') no-repeat center / cover;

				@include breakpoint(small) {
					background-image: image-url('layout/giant-contact.jpg');
				}
				@include breakpoint(giant) {
					background-image: image-url('layout/contact.jpg');
				}
			}
		}
	}

	&:after {
		content: '';
		position: absolute;
		inset: 0;
		background: linear-gradient(to bottom, $light, rgba($light, 0) 10%, rgba($light, 0) 90%, $light);
		z-index: -2;
	}
}


// FOOTER
//////////////////////////////

#footer {
	padding-bottom: rem($bar-height);
	background: $dark;
	margin-top: clamp(-5rem, -10vw, -3rem);
	z-index: 20;

	dl {
		dt, dd {
			font-weight: 400;
			color: $light;
		}
	}
	.phone {
		&:not(:hover):not(:focus):not(:active) {
			font-weight: 400;
			color: $light;
		}
	}
	.socials {
		padding: rem($base-line-height * 2) 0;
		display: inline-grid;
		grid: auto auto / auto auto auto;
		align-items: center;
		gap: rem($base-line-height);
		
		@include breakpoint(tiny) {
			grid-template-rows: 1fr;

			a {
				grid-row: 1;
			}
		}
		@include breakpoint(medium) {
			@include breakpoint(full, max) {
				grid-template-rows: auto auto;
				
				a {
					grid-row: auto;
				}
			}
		}

		svg {
			display: block;

			path {
				transition: .3s ease-in-out;
			}
		}
		a {			
			&.instagram {
				position: relative;

				svg.gradient {
					position: absolute;
					inset: 0;
					z-index: 1;
					transition: .3s ease-in-out;
				}
				&:not(:hover):not(:focus):not(:active) {
					svg.gradient {
						opacity: 0;
						visibility: hidden;
					}
				}
			}

			&:not(:hover):not(:focus):not(:active):not(.instagram) {
				svg path {
					fill: $light;

					&.light {
						fill: $dark;
					}
				}
			}

			
		}
	}
	
	.footer-menus {
		display: grid;
		gap: rem($base-line-height * 2);
		grid: auto auto / 1fr 1fr;
		justify-content: space-between;

		@include breakpoint(small) {
			grid-template-columns: auto auto auto;
		}
		@include breakpoint(medium) {
			grid-template-columns: 1fr 1fr;
		}
		@include breakpoint(giant) {
			grid-template-columns: auto auto auto;
		}
		@include breakpoint(full) {
			grid-template-columns: auto auto auto auto;
		}

		&.space {
			@include breakpoint(medium) {
				padding: 0 !important;
			}
		}
		
		> div {
			&.security-menu-cont {
				@include breakpoint(micro) {
					grid-row: 2 / 4;
				}
			}
			&.support-menu-cont {
				@include breakpoint(micro) {
					grid-column: 2;
					grid-row: 2;
				}
				@include breakpoint(full) {
					grid-column: 4;
					grid-row: 1;
				}
			}
			&.legal-menu-cont {
				@include breakpoint(micro) {
					grid-column: 2;
					grid-row: 3;
				}
				@include breakpoint(full) {
					grid-column: 4;
					grid-row: 2;
				}
			}

			&.company-menu-cont,
			&.services-menu-cont,
			&.security-menu-cont {
				@include breakpoint(full) {
					grid-row: 1 / 3;
				}
			}
		}
		
		.h6, li a {
			margin: 0 !important;
			padding: 0.3em 0;
		}
		.h6 {
			color: $medium;
			padding-top: 0;
		}

		.menu {
			li {
				a {
					color: darken($border, 5%);
					hyphens: auto;
					display: block;
				}
				&.menu-item-12837 {
					a {
						&:after {
							content: image-url('layout/teamviewer.svg');
							display: inline-block;
							margin: 0 .5em;
							vertical-align: -.15em;
						}
					}
				}
				&:hover,
				&:focus,
				&:active {
					&:not(.current-menu-item) {
						a {
							color: lighten($primary, 15%);
						}
					}
				}
				&.current-menu-item  {
					a {
						font-weight: 900;
						color: $light;
					}
				}
			}
		}
	}
}