.category_select {
    overflow: visible;

    .category-list {
        display: flex !important;
        width: 100%;
        flex-flow: column nowrap;
        padding-right: 2.7em;
    
        .cat-item {
            cursor: pointer;
            padding: 0;
            opacity: 0;
            visibility: hidden;
            overflow: hidden;
            max-height: 0;
            transition: padding .3s .2s linear, max-height .3s .2s linear, opacity .3s linear, visibility .3s linear;

            a {
                display: block;
            }
            
            &.default-cat {
                order: -1;
                padding: .3em 0;
                max-height: 5em;
                opacity: 1;
                visibility: visible;
            }
            &.current-cat {
                order: -2;
                padding: .3em 0;
                max-height: 5em;
                opacity: 1;
                visibility: visible;

                ~ .default-cat {
                    padding: 0;
                    max-height: 0;
                    opacity: 0;
                    visibility: hidden;
                }
            }
        }
    }
    &.open {
        .cat-item {
            padding: .3em 0 !important;
            max-height: 5em !important;
            opacity: 1 !important;
            visibility: visible !important;
            transition: padding .3s linear, max-height .3s linear, opacity .3s .2s linear, visibility .3s .2s linear;

            &.current-cat a {
                color: lighten($primary, 15%);
            }
        }
    }
}


.categories {
    overflow: visible;

    > * {
        max-height: 15rem;
        transition: opacity .6s ease-in-out, max-height 4s .1s ease-in-out, margin 2s .1s ease-in-out;
        overflow: hidden;
        
        &.hiding {
            transition: opacity .6s ease-in-out, max-height 1s .1s ease-in-out, margin 1s .1s ease-in-out;
            opacity: 0;
            max-height: 0;
            margin: 0;
        }
    }
}