// Use this file for your custom mixins/functions

/*
* Mixin for Responsive Spaces
* @author Jasmin Reiff
* @version 1.2
* @category scss mixin
*/

@mixin space($space)
{
	padding-top: #{rem($base-line-height * 2)};
	padding-bottom: #{rem($base-line-height * 1)};
	
	@if ($space < ($base-line-height * 3))
	{
		@include breakpoint(small) 
		{
			padding-top: rem($space);
			padding-bottom: rem($space - $base-line-height);
		}
	}
	@else 
	{
		@include breakpoint(small)
		{
			padding-top: #{rem($base-line-height * 3)};
			padding-bottom: #{rem($base-line-height * 2)};
		}

		@if ($space < ($base-line-height * 5))
		{
			@include breakpoint(giant) 
			{
				padding-top: rem($space);
				padding-bottom: rem($space - $base-line-height);
			}
		}
		@else 
		{
			@include breakpoint(giant)
			{
				padding-top: #{rem($base-line-height * 5)};
				padding-bottom: #{rem($base-line-height * 4)};
			}
			@include breakpoint(huge) 
			{
				padding-top: rem($space);
				padding-bottom: rem($space - $base-line-height);
			}		
		}
	}
}
.space:not(.no-space) {
	@include space(135px);
}
.space-small:not(.no-space) {
	@include space(50px);
}
.space-mid:not(.no-space) {
	@include space(90px);
}
.space-big:not(.no-space) {
	@include space(180px);
}
.space-top-none {
	padding-top: 0 !important;
}
.space-bottom-none {
	padding-bottom: 0 !important;
}


// BLURS
//////////////////

// @keyframes blurs {
// 	from {
// 		opacity: 0.05;
// 		scale: 0.5;
// 	}
// 	to {
// 		opacity: 0.2;
// 		scale: 1.2;
// 	}
// }

// .blurs {
// 	overflow: hidden;
// 	position: absolute;
// 	inset: 0;
// 	z-index: -2;

// 	b {
// 		display: block;
// 		width: 20em;
// 		height: 20em;
// 		border-radius: 50%;
// 		background: $primary;
// 		position: absolute;
// 		filter: blur(99px);
// 		opacity: 0.05;
		
// 		&:nth-child(2n) {
// 			background: $primary;
// 		}
// 		&:nth-child(3n) {
// 			background: $info;
// 		}
// 		&:nth-child(4n) {
// 			background: $secondary;
// 		}
// 		&:nth-child(5n) {
// 			background: $tertiary;
// 		}
// 		&:nth-child(6n) {
// 			background: $alert;
// 		}
// 	}
// }

// // Total numner of blurs
// $blurs: 40;

// // Randomize the animation and positioning for each snowflake
// @for $i from 1 through $blurs {
//   // Position of the snowflake on the y-axis
//   $top: random(80) * 1%;

//   // Position of the snowflake on the x-axis
//   $left: random(80) * 1%;

//   // Animation delay for the flake
// //   $delay: random(20) - 1s;

//   // Floating span for the flake
// //   $duration: (random(20) + 5) + 25s;

//   // Size of the flake
//   $size: random(5);

//    /* Snowflake ##{$i} */
//   .blurs b:nth-of-type(#{$i}) {
//     // animation-name: blurs-#{$i};
//     // animation-delay: $delay;

//     // Play the animation for anything between 5-10 seconds
//     // animation-duration: $duration;
//     // animation-iteration-count: infinite;
//     // animation-direction: alternate;
//     left: $left;
//     top: $top;
//     font-size: $size * 0.5rem;
//   }
  
//   // Animation for glitter ##{$i}
//     // @keyframes blurs-#{$i} {
//     //     0% {
//     //         left: $left;
//     //         top: $top;
// 	// 		opacity: 0.01;
//     //     }
//     //     25% {
//     //         top: $top - 9%;
//     //         left: $left - 15%;
//     //     }

//     //     50% {
//     //         top: $top + 15%;
//     //         left: $left;
//     //     }

//     //     75% {
//     //         top: $top - 5%;
//     //         left: $left + 9%;
//     //     }
//     //     100% {
//     //         opacity: 0.07;
//     //     }
//     // }
// }