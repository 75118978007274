article {
	&.career-tile,
	&.blog-tile {
		&, .box {
			display: flex;
			flex-flow: column nowrap;
			justify-content: flex-start;
			align-items: flex-start;
		}
		> * {
			width: 100%;
		}
		> a {
			height: rem(180px);
			background: $primary;
			overflow: hidden;
			border-radius: 0.5rem 0.5rem 0 0;

			@include breakpoint(giant) {
				height: rem(245px);
			}

			img {
				height: 100%;
				object-fit: cover;
				transition: 0.4s ease-in-out;
			}

			&:hover, &:active, &:focus {
				// background: lighten($info, 6%);

				img {
					opacity: 0.5;
				}
			}
			&:empty {
				display: none;

				+ .box {
					border-radius: 0.5rem;
				}
			}
		}
		.box {
			border-radius: 0 0 0.5rem 0.5rem;
			
			&, p {
				flex-grow: 1;
			}
			a:not(.btn) {
				&:after {
					content: none;
				}
				
				&, .h4 {
					transition: 0.4s ease-in-out;
				}

				&:hover, &:active, &:focus {	
					&, .h4 {
						color: $secondary;
					}
				}
			}
		}
	}
	&.career-tile {
		.box {
			border-radius: 0.5rem;
		}
	}
	&.blog-single {
		img.wp-post-image {
			position: absolute;
			height: clamp(rem(90px), 20vw, rem(340px));
			top: 0;
			left: 0;
			width: 100%;
			z-index: -1;
			object-fit: cover;
			filter: blur(4px);
			opacity: 0.2;
		}
		p {
			hyphens: auto;
		}

		h2, h3, h4, h5, h6 {
			margin-bottom: 0.3em;
			padding-top: 0.5em;
		}
		li {
			h2, h3, h4, h5, h6 {
				&:first-child {
					display: inline-block;
					text-indent: -0.15em;
				}
			}
		}
		img {
			width: auto;
		}
		
		// header {
		// 	position: relative;

		// 	h1 {
		// 		position: absolute;
		// 	}

		// 	&:after {
		// 		content:"";
		// 		height:100vh;
		// 		width:100%;
		// 		background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(255,255,255,1) 100%);
		// 		position: absolute;
		// 		bottom:0;
		// 		left:0;
		// 		display:block
		// 	}
		// }
	}
}
.site-pagination {
	display: flex;
	justify-content: center;
	width: 100%;
	padding: 3rem rem($base-gap);

	a {
		@extend .btn;

		@include breakpoint(small, max) {
			font-size: rem(13px);
			line-height: 1.3em;
		}
	}
	.nav-previous a:before,
	.nav-next a:after {
		font-family: $icon-font;
		font-size: 0.4em;
		vertical-align: 0.5em;
	}
	.nav-previous {
		a {
			border-radius: 9em 0 0 9em;
			margin-right: 1px;
			
			&:before {
				content: map-get($icon-map, left);
				margin-right: 2em;
			}
		}
		&:not(:empty) {
			+ .nav-next a {
				border-radius: 0 9em 9em 0;
			}
		}
	}
	.nav-next {
		a {
			&:after {
				content: map-get($icon-map, right);
				margin-left: 2em;
			}
		}
	}
}